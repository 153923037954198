<template>
  <div id="modalPreAprobado" class="modal-overlay">
    <div class="modal-container">
      <span class="close-modal d-lg-none" @click="$emit('close')">X</span>
      <div class="modal-header-pa">
        <h2 class="d-none d-xl-block modal-title">¿Por qué debo introducir mis credenciales bancarias para continuar?</h2>
        <h2 class="d-xl-none modal-title">¿Por qué introducir mis credenciales bancarias?</h2>
      </div>
      <div class="modal-content-pa">
        <p class="modal-subtitle">Para poder ofrecerle un <b>préstamo sin aval en menos de 48h</b> necesitamos analizar sus ingresos.</p>
        
        <div class="security-items">
          <div class="security-item">
            <img src="@/assets/img/pre-aprobado/i_security.svg" alt="Seguridad">
            <p><b>Máxima seguridad</b>: sus datos están protegidos.</p>
          </div>

          <div class="security-item">
            <img src="@/assets/img/pre-aprobado/i_shield.svg" alt="No almacenamiento">
            <p><b>No realizamos acciones en tu cuenta</b>. Solo analizamos tus ingresos.</p>
          </div>

          <div class="security-item">
            <img src="@/assets/img/pre-aprobado/i_security_lock.svg" alt="Sin acciones">
            <p><b>No se almacenan tus credenciales</b> en ningún caso.</p>
          </div>

          <div class="security-item">
            <img src="@/assets/img/pre-aprobado/i_eye.svg" alt="Servicio">
            <p class="last-item"><b>Servicio prestado por Fintern Limited</b><br class="d-xl-none"><span> (Con autorización de la UE para prestar Account Information and Payment Initiation Services)</span>.</p>
          </div>
        </div>

        <p class="d-none d-xl-block paragraph-modal">
          Si continua, podremos ofrecerle un préstamo en máximo 48h sin necesidad de vehículo ni más documentación. 
        </p>

        <div class="buttons-modal">
          <button class="btn-continue" @click="$emit('next')">
            Continuar
          </button>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalPreAprobado',
  emits: ['next', 'close']
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";

  #modalPreAprobado.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.688);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;

    .modal-container {
      width: 90%;
      max-width: 500px;
      background: $blue;
      border-radius: 30px;
      padding: 0;
      position: relative;
    }

    .close-modal{
      position: absolute;
      top: -2.5rem;
      background: #999999;
      right: 0px;
      padding: 0.3rem 0.7rem;
      border-radius: 50px;
      cursor: pointer;
      color: white;
      font-size: 1rem;
      font-weight: 700;
    }
    
    .modal-content-pa {
      display: flex;
      flex-direction: column;
      background: #E7F1F9;
      border-radius: 0 0 30px 30px;
      gap: 1.5rem;
      padding: 1.2rem;
      margin: 0 0.4rem 0.55rem 0.4rem;
    }
    
    .modal-header-pa {
      background: $blue;
      margin: 0;
      padding: 1.5rem;
      border-radius: 30px 30px 0 0;
    }

    .modal-title {
      color: white;
      font-size: 1.3rem;
      font-weight: 700;
      text-align: center;
      line-height: normal;
    }
    
    .modal-subtitle {
      color: $blue;
      font-size: 0.85rem;
      text-align: center;
      margin: 0;
    }
    
    .security-items {
      display: flex;
      flex-direction: column;
      padding-left: 1rem;
      padding-right: 0.5rem;
      gap: 1rem;
    }
    
    .security-item {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
    
      img {
        width: 24px;
        height: 24px;
      }
    
      p {
        margin: 0;
        font-size: 0.85rem;
        color: $blue;
        line-height: 1.2;
      }

      .last-item{
        font-size: 0.65rem;
      }

      .last-item span{
        font-size: 0.60rem;
      }
    }
  
    .btn-continue {
      background: $orange;
      color: white;
      border: none;
      padding: 0.65rem 2.5rem;
      font-size: 1.38rem;
      font-weight: 700;
      width: 100%;
      margin-bottom: 1.5rem;
      cursor: pointer;
      transition: background-color 0.2s;
      border-radius: 30px;
      box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);

    }

  }


  @import "bootstrap/scss/_functions.scss";
  @import "bootstrap/scss/_variables.scss";
  @import "bootstrap/scss/_mixins.scss";

  @include media-breakpoint-up(md) {
    #modalPreAprobado.modal-overlay {

      padding: 2.5rem;

      .modal-container {
        width: 60%;
      }
      .modal-content-pa {
        padding: 1.8rem;
      }
      .modal-title {
        font-size: 1.5rem;
      }
      .modal-subtitle {
        font-size: 1rem;
      }
      .security-item {
        img {
          width: 30px;
          height: 30px;
        }
        p {
          font-size: 1rem;
        }
      }

    }
  }

  @include media-breakpoint-up(lg) {
    
    #modalPreAprobado.modal-overlay {

      padding: 2.5rem;

      .btn-continue{
        max-width: 560px;
        font-size: 2rem;
        margin-top:1.4rem;
        justify-self: center;
        text-align: center;
        display: flex;
        justify-content: center;
      }  
      .modal-container {
        width: 70%;
        max-width: 1024px;
      }
      .modal-content-pa {
        padding: 2.5rem
      }
      .modal-title {
        font-size: 1.5rem;
      }
      .modal-subtitle {
        font-size: 1rem;
      }
      .security-item {
        img {
          width: 30px;
          height: 30px;
        }
        p {
          font-size: 1rem;
        }
        .last-item{
          font-size: 0.85rem;
        }
        .last-item span{
          font-size: 0.80rem;
        }
      }

    }
  }

  @include media-breakpoint-up(xl) {
    #modalPreAprobado.modal-overlay {

      padding: 2.5rem;

      .modal-container {
        width: 65%;
        max-width: 1440px;
      }
      .modal-header-pa {
        padding: 2.5rem 3.4rem;
      }
      .modal-content-pa {
        padding: 2.5rem 3.4rem;
      }
      .modal-title {
        font-size: 2rem;
        text-align:center;
      }
      .modal-subtitle {
        font-size: 1.5rem;
        font-weight: 700;
      }
      .paragraph-modal{
        font-size: 1.40rem;
        color: $blue;
        font-weight: 600;
        text-align: center;
        line-height: normal;
      }

      .security-items{
       margin: 1.2rem 0;
      }
      .security-item {
        img {
          width: 30px;
          height: 30px;
        }
        p {
          font-size: 1.35rem;
        }
        .last-item{
          font-size: 1.35rem;
        }
        .last-item span{
          font-size: 0.9rem;
        }
      }

    }
  }

</style>