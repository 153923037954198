
<template>
    <div id="main-section" class="container-fluid p-0 pb-2 pt-3">
        <main>
            <slot></slot>
        </main> 
    </div>
</template>


<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-up(lg) {
    #main-section{
      padding-top: 5rem !important;
    }
}


@include media-breakpoint-up(xxl) {
    #main-section{
      max-width: 1440px;
    }
}

</style>