  <template>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-11 col-xl-9">
        <!-- Pre-aprobado Card -->
        <div class="pre-approved-card text-center">
          <h1 class="title">Preguntas Frecuentes</h1>
          <div class="faq-container">
            <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
              <div class="faq-question" @click="toggleFaq(index)">
                <h3>{{ faq.question }}</h3>
                <!-- <i :class="['fas', activeFaq === index ? 'fa-chevron-up' : 'fa-chevron-down']"></i> -->
              </div>
              <div class="faq-answer" :class="{ 'active': activeFaq === index }">
                {{ faq.answer }}
              </div>
            </div>
          </div>
          
         
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta'
  import { ref } from 'vue'
  import { useStore } from 'vuex'
  export default {
    name: 'ThankYou',
    components: {
    },
    setup() {
      const store = useStore();
      const activeFaq = ref(null);
 
      useMeta({
        title: 'Preguntas Frecuentes - Ibancredit',
        meta: [
          { name: 'robots', content: store.state.dominio == 'ibancar.com' ? 'index' : 'noindex' },
          { name: 'description', content: 'Finaliza tu solicitud de préstamo pre-aprobado con Ibancredit' }
        ]
      })

      const faqs = [
        {
          question: '¿Qué es Ibancredit?',
          answer: 'Ibancredit es una alternativa a la financiación con coche como aval cuando este no cumple los requisitos por kilometraje, estado, antigüedad u otros factores. A través de open banking, realizamos un análisis seguro y rápido de tu situación financiera para ofrecerte una propuesta personalizada.'
        },
        {
          question: '¿Cómo funciona Ibancredit?',
          answer: 'Nuestro proceso es 100% digital y seguro. Conectamos con tu banco de manera segura mediante open banking, realizamos un análisis de tu historial financiero sin almacenar datos sensibles. Posteriormente, generamos una propuesta adaptada a tu situación.'
        },
        {
          question: '¿Es seguro conectar mi cuenta bancaria con Ibancredit?',
          answer: 'Sí, conectar  tu cuenta bancaria con Ibancredit es completamente seguro. Utilizamos la tecnología de Arcopay, la marca comercial de MORPHEUS AIOLOS S.L., una Entidad de Pago autorizada por el Banco de España para la prestación de servicios de iniciación de pago y de información sobre cuentas. Esto significa que todos los servicios ofrecidos cumplen con la normativa vigente y están supervisados por el Banco de España, asegurando la máxima protección y transparencia para los usuarios.'
        },
        {
          question: '¿Ibancredit almacena mis datos bancarios?',
          answer: 'No, Ibancredit no almacena, ni tiene acceso a tus credenciales bancarias. Solo obtenemos un permiso temporal para analizar tu situación financiera y brindarte la mejor propuesta posible.'
        },
        {
          question: '¿Pueden hacer cargos en mi cuenta bancaria sin mi autorización?',
          answer: 'No, en ningún momento realizaremos cargos automáticos ni movimientos en tu cuenta sin tu consentimiento. Open banking no permite transacciones, solo realiza una consulta segura de información.'
        },
        {
          question: '¿Qué bancos son compatibles con Ibancredit?',
          answer: 'Trabajamos con la mayoría de los bancos en España y otros países europeos que cumplen con los estándares de open banking. Durante el proceso, te mostraremos si tu banco es compatible.'
        },
        {
          question: '¿Puedo revocar el acceso a mi cuenta bancaria?',
          answer: 'Sí, en cualquier momento puedes revocar los permisos de acceso desde la configuración de tu banco. Nuestro acceso es temporal y expira automáticamente tras completar el análisis financiero.'
        },
        {
          question: '¿Cómo puedo contactar con Ibancredit si tengo más dudas?',
          answer: 'Puedes escribirnos a nuestro correo de soporte (info@ibancredit.com) puedes llamarnos al +34 952 864 645 o contactarnos por WhatsApp en +34 913 247 400. Estamos disponibles para ayudarte en todo momento.'
        }
      ]
      const toggleFaq = (index) => {
        activeFaq.value = activeFaq.value === index ? null : index;
      };

      return {
        faqs,
        activeFaq,
        toggleFaq
      }
    },
  
  }
  </script>

  <style scoped lang="scss">
    @import "@/assets/styles/variables.scss";

    h3{
      text-align: left;
      font-size: 0.88rem;
      color: $orange;
      margin-bottom: 0;
      font-weight: 700;
    }

    .title{
      display: block;
      font-size: 1.57rem;
      text-align: center;
      line-height: normal;
      color: $blue;
      margin-bottom: 2.5rem;
      font-weight: bolder;
    }


    .pre-approved-card {
      background: white;
      border-radius: 12px;
      padding: 2rem;
      //margin: 1rem;
      margin-top: 4rem;
      text-align: left;
    }

    .btn-continue {
      background: $orange;
      color: white;
      border: none;
      padding: 0.65rem 2.5rem;
      font-size: 1.38rem;
      font-weight: 700;
      width: 100%;
      margin-bottom: 1.5rem;
      cursor: pointer;
      transition: background-color 0.2s;
      border-radius: 30px;
      box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .faq-container {
      max-width: 800px;
      margin: 0 auto;
    }

    .faq-item {
      margin-bottom: 1rem;
      overflow: hidden;
      position: relative;

      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0.2rem;
        width: 10px;
        height: 13px;
        background-image: url('../../assets/img/arrowDownOrange.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
      }

    }

    .faq-question {
      padding: 0  1.5rem 1rem 1.5rem;
      background-color: white;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      font-weight: 600;

      i {
        transition: transform 0.3s ease;
      }
    }

    .faq-answer {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
      padding: 0 1.5rem;
      font-weight: 500;
      font-size: 0.75rem;
      color: $blue;
      text-align: left;
      
      &.active {
        max-height: 500px;
        padding: 0 1.5rem 1.5rem 1.5rem;
      }
    }

    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";

    @include media-breakpoint-up(md) {
   
    }

    @include media-breakpoint-only(lg) {
      .title{
        font-size: 2.6rem !important;
        text-align: center;
      }
    }

    @include media-breakpoint-up(lg) {
      .title{
        font-size: 3rem;
        margin-bottom: 4.4rem;
      }
      H3{
        font-size:1.2rem;
      }

      .pre-approved-card {
        padding: 2rem 0;
      }
      
      .faq-item {
        &::before{
         
          width: 15px;
          height: 18px;
        }
  
      }

    }

    @include media-breakpoint-up(xl) {
      H3{
        font-size:1.8rem;
      }

      .pre-approved-card {
        padding: 2rem 0;
      }
      
      .faq-item {
        &::before{
          top: 0.3rem;
          width: 20px;
          height: 23px;
        }
  
      }
      .faq-question{
        padding: 0 0 1.5rem 2.5rem;
      }
      
      .faq-answer{
        font-size: 1.2rem;
        padding: 0 0 0 2.5rem;

        &.active{
          font-size: 1.2rem;
          padding: 0 0 2.5rem 2.5rem;
        }
      }
    }

    
  </style>
