  <template>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-11 col-xl-9">
        <!-- Pre-aprobado Card -->
        <div class="pre-approved-card text-center">
          <h1 class="title">Tu solicitud ha caducado</h1>
          <p class="subtitle"> 
            Han pasado demasiados días desde que te envíamos este enlace. 
            <br>
            <br>
            Si quieres solicitar un préstamo con Ibancredit, haz click en reiniciar solicitud y podrás volver a solicitar el préstamo Ibancredit, sin aval. 
            <br>
          </p>
          <div class="group-button">
            <button class="btn-continue" @click="nextStep">
              Reiniciar solicitud
            </button>
            <button class="btn-wsp d-lg-none" @click="nextStep">
              Contactar por WhatsApp
            </button>
          </div>
        </div>  
      </div>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta'
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  export default {
    name: 'ExpiredRequest',
    emits: ['next', 'back'],
    components: {
    },
    setup(props, { emit }) {
      const store = useStore();
  
      useMeta({
        title: 'Solicitud Expirada - Ibancredit',
        meta: [
          { name: 'robots', content: store.state.dominio == 'ibancar.com' ? 'index' : 'noindex' },
          { name: 'description', content: 'Solicitud Expirada - Ibancredit' }
        ]
      })

      const nextStep = () => emit('next');
      const previousStep = () => emit('back');

      return {
        afiliado: computed(() => store.state.afiliado),
        nextStep,
        previousStep
      }
    }
  
  }
  </script>

  <style scoped lang="scss">
    @import "@/assets/styles/variables.scss";

    h2{
      text-align: left;
      font-size: 1.12rem;
      color: $blue;
      margin-bottom: 0;
      font-weight: 700;
    }

    .title{
      display: block;
      font-size: 1.57rem;
      text-align: left;
      line-height: normal;
      color: $blue;
      margin-bottom: 1rem;
      font-weight: bolder;
    }

    .subtitle {
      color: $blue;
      font-size: 0.813rem;
      text-align: left;
      margin-bottom: 2rem;
      font-weight: 500;
      line-height: normal;
    }

    .pre-approved-card {
      background: white;
      border-radius: 12px;
      padding: 2rem;
      //margin: 1rem;
      margin-top: 4rem;
      text-align: left;
    }

    .btn-continue {
      background: $orange;
      color: white;
      border: none;
      padding: 0.65rem 1rem;
      font-size: 1.38rem;
      font-weight: 700;
      width: 100%;
      margin-bottom: 1.5rem;
      cursor: pointer;
      transition: background-color 0.2s;
      border-radius: 30px;
      box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .btn-wsp {
      background: #fff;
      color: #5AA33B;
      border: 1px solid #5AA33B;
      padding: 0.65rem 1rem;
      font-size: 0.813rem;
      font-weight: 700;
      width: 100%;
      margin-bottom: 1.5rem;
      cursor: pointer;
      transition: background-color 0.2s;
      border-radius: 30px;
      box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .banks{
      display: flex;
      flex-direction: row;
      justify-content: left;
      gap: 1rem;
      margin: 1rem 0 3rem 0;
      img{
        max-width: 60px;
      }
    }

    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";

    @include media-breakpoint-only(lg) {
      .title{
        font-size: 2.6rem !important;
        text-align: center;
      }
    }

    @include media-breakpoint-up(lg) {
      .title{
        font-size: 3rem;
        text-align: center;
        margin-bottom: 1.4rem;
      }
      .subtitle{
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
      }
      h2{
        font-size:1.9rem;
      }
      .btn-continue{
        max-width: 560px;
        font-size: 2rem;
        margin-top:3rem;
        justify-self: center;
        text-align: center;
        display: flex;
        justify-content: center;
      }
      .btn-continue{
        margin-bottom: 5rem;
      }

      .group-button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
      }

      .banks{
        margin-bottom: 0;
        img{
          min-width: 100px;
        }
      }

      .pre-approved-card {
        padding: 2rem 0;
      }  
      
    }
  </style>
