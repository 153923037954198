  <template>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-11 col-xl-9">
        <!-- Pre-aprobado Card -->
        <div class="pre-approved-card text-center">
          <h1 class="title">Tu préstamo sin aval, más fácil y rápido.</h1>
          <img src="@/assets/img/pre-aprobado/i_completed.svg">
          <h2>Solicitud completada</h2>
          <h3>¿Qué sucede ahora?</h3>
          <div class="timeline">
            <ul>
              <div class="timeline__group">
                <li><p>Solicitud de préstamo Ibancredit</p></li>
                <li><p>Analizamos tu perfil crediticio</p></li>
                <li class="timeline__group__item--last"><p>En máximo 48h recibirás una respuesta a tu solicitud. </p></li>
              </div>

              <li class="timeline__item--last">
                <img src="@/assets/img/pre-aprobado/i_wsp.svg">
                <p>Recibirás una respuesta con todos los detalles sobre el importe de tu préstamo aprobado a través de WhatsApp.
              <br><br>              
              Si tienes alguna duda, puedes contactarnos al 952 864 645 o por <a target="_blank" href="https://wa.me/+34952864645?text=Hola!%20He%20solicitado%20un%20préstamo%20Ibancredit.">whatsapp</a>.
              <br><br>
              Puedes cerrar esta ventana.</p></li>
            </ul>
          </div>
 
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta'
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  export default {
    name: 'ThankYou',
    components: {
    },
    setup() {
      const store = useStore();
  
      // Prevent going back
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, '', window.location.href);
      };

      useMeta({
        title: 'Gracias por tu solicitud - Ibancredit',
        meta: [
          { name: 'robots', content: store.state.dominio == 'ibancar.com' ? 'index' : 'noindex' },
          { name: 'description', content: 'Finaliza tu solicitud de préstamo pre-aprobado con Ibancredit' }
        ]
      })

      const sessionPreAprobado = computed(() => store.state.sessionPreAprobado);
      store.commit('setSessionPreAprobado', null); // Clear the session

      return {
        sessionPreAprobado
      }
    },
  
  }
  </script>

  <style scoped lang="scss">
    @import "@/assets/styles/variables.scss";

    h3{
      text-align: left;
      font-size: 0.85rem;
      color: #AAA6A6;
      margin-bottom: 0;
      font-weight: 700;
    }

    h2{
      text-align: center;
      font-size: 1.12rem;
      color: $blue;
      margin-bottom: 3rem;
      margin-top: 1.5rem;
      font-weight: 700;
    }

    .title{
      display: block;
      font-size: 1.57rem;
      text-align: center;
      line-height: normal;
      color: $blue;
      margin-bottom: 2.5rem;
      font-weight: bolder;
    }

    .subtitle {
      color: $blue;
      font-size: 0.813rem;
      text-align: left;
      margin-bottom: 2rem;
      font-weight: 500;
      line-height: normal;
    }

    .pre-approved-card {
      background: white;
      border-radius: 12px;
      padding: 2rem;
      //margin: 1rem;
      margin-top: 4rem;
      text-align: left;
    }

    .btn-continue {
      background: $orange;
      color: white;
      border: none;
      padding: 0.65rem 2.5rem;
      font-size: 1.38rem;
      font-weight: 700;
      width: 100%;
      margin-bottom: 1.5rem;
      cursor: pointer;
      transition: background-color 0.2s;
      border-radius: 30px;
      box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .timeline{
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .timeline__group{
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        position: relative;

        &::before{
          content: '';
          position: absolute;
          top: 10px;
          left: -14.6px;
          width: 5px;
          height: 70%;
          z-index:1;
          background-color: rgb(255, 255, 255);
          //border: 0.2px solid #d8d8d8;
          box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
        }
        li{
          position: relative;
        }

        li::before {
          content: '';
          position: absolute;
          z-index:2;
          top: 4px;          
          left: -20px;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          background-color: $blue;
          box-shadow: 0px 3px 2px 0px #00000040;
        }

        li.timeline__group__item--last::before{
          background-color: #fff;
          border: 1.5px solid #f3f3f3;
        }
      }

      ul{
        padding-left:1.5rem;
        gap: 0.6rem;
        display: flex;
        flex-direction: column;
        text-align: left;
      }

      li{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        gap: 0.6rem;

        p{
          font-size: 1rem;
        }
      }

      img{
        width: 25px;
      }

      li.timeline__item--last{
        margin-top: 1rem;
        position: relative;

        a{
          color: #70E23E;
        }

        img{
          position: absolute;
          left: -25px;
        }

        p{
          font-weight: 400;
          font-size: 0.88rem;
        }
      }

      p{
        font-size: 0.85rem;
        font-weight: 600;
        margin-bottom: 0;
        line-height: normal;
      }

    }

 

    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";

    @include media-breakpoint-up(md) {
      .timeline .timeline__group::before{
        height: 80%;
      }
    }

    @include media-breakpoint-only(lg) {
      .title{
        font-size: 2.6rem !important;
        text-align: center;
      }
    }

    @include media-breakpoint-up(lg) {
      .title{
        font-size: 3rem;
        margin-bottom: 4.4rem;
      }
      .subtitle{
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 3rem;
      }
      h2{
        font-size:1.9rem;
      }
      H3{
        font-size:2rem;
      }

      .pre-approved-card {
        padding: 2rem 0;
      }  

      .timeline {
        ul{
          padding-left: 2.5rem;
        }

        li.timeline__item--last {
          margin-top: 2rem;

          img{
            width: 48px;
            left: -40px;
          }

           p{ 
            font-size: 1.6rem;
          }
        }

        .timeline__group{
          gap: 2.5rem;

          &::before{
            left: -17px;
          }

          li::before{
            width: 40px;
            height: 40px;
            left: -35px;
          }
        }

        li {
          padding-left: 1.5rem;

          p{
            font-size: 2rem;
            color: #353739;
            font-weight: 600;
          }
        }
      }
      
    }

    @include media-breakpoint-up(xl) {
      .steps{
        .steps__item{
          padding: 1rem 2.4rem;
        }
      }
    }
  </style>
