  <template>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-11 col-xl-9">
        <!-- Pre-aprobado Card -->
        <div class="pre-approved-card text-center">
          <h1 class="title">Tu préstamo sin aval, más fácil y rápido.</h1>
          <p class="subtitle"> 
            No pudimos ofrecerte el préstamo Ibancar que solicitaste, pero tenemos una opción fácil y rápida, sin aval ni más documentación.  
          </p>

          <div class="container-icons">
            <div class="container-icons__item container-icons__item--1">
              <img src="@/assets/img/pre-aprobado/i_ibancar-logo.svg" alt="Ibancar Logo">
            </div>
            <div class="container-icons__item container-icons__item--2">
              <img :src="require('@/assets/img/pre-aprobado/i_form.svg')" class="d-lg-none" alt="Ibancar Solicitud">
              <img :src="require('@/assets/img/pre-aprobado/i_gears.svg')" class="d-none d-lg-block" alt="Ibancar Solicitud">
            </div>
            <div class="container-icons__item container-icons__item--3">
              <img :src="require('@/assets/img/pre-aprobado/i_document.svg')" class="d-lg-none" alt="Ibancar Formulario">
              <img :src="require('@/assets/img/pre-aprobado/i_bank.svg')" class="d-none d-lg-block" alt="Ibancar Formulario">
            </div>
          </div>

          <h3>Siguientes pasos:</h3>
          <div class="steps">
            <div class="steps__item steps__item--1">
              <img src="@/assets/img/pre-aprobado/i_check.svg">
              <p class="d-lg-none">Selecciona tu banco.</p>
              <p class="d-none d-lg-block">Selecciona tu banco principal</p>
            </div>
            <div class="steps__item steps__item--2">
              <img src="@/assets/img/pre-aprobado/i_key.svg">
              <p class="d-lg-none">Introduce tus credenciales bancarias y sigue las instrucciones.</p>
              <p class="d-none d-lg-block">Introduce tus credenciales</p>
            </div>
            <div class="steps__item steps__item--3">
              <img src="@/assets/img/pre-aprobado/i_verify.svg">
              <p class="d-lg-none">Verificaremos tus datos para poder ofrecerte un préstamo sin aval.</p>
              <p class="d-none d-lg-block">Verificamos y analizamos tu perfil</p>
            </div>
            <div class="steps__item steps__item--4">
              <img src="@/assets/img/pre-aprobado/i_notification.svg">
              <p class="d-lg-none">En 48h tendrás una respuesta sobre tu solicitud de préstamo.</p>
              <p class="d-none d-lg-block">Respuesta en máximo 48h</p>
            </div>
          </div>

          <button class="btn-continue" @click="nextStep">
            Continuar
          </button>

          <div class="incomes">
            <h3>Tus ingresos serán analizados automáticamente.</h3>
            <ul>
              <li>
                <img src="@/assets/img/pre-aprobado/i_security.svg">
                <p>Máxima seguridad.</p>
              </li>
              <li>
                <img src="@/assets/img/pre-aprobado/i_shield.svg">
                <p>No se almacenan tus credenciales en ningún caso.</p>
              </li>
              <li>
                <img src="@/assets/img/pre-aprobado/i_security_lock.svg">
                <p>No podemos llevar a cabo ninguna acción en tu cuenta.</p>
              </li>
              <li>
                <img src="@/assets/img/pre-aprobado/i_eye.svg">
                <p>Servicio prestado por Fintern Limited (Con autorización de la UE para prestar Account Information and Payment Initiation Services).</p>
              </li>
              <li class="incomes__item__last">
                <p>MORPHEUS AIOLOS S.L., titular de la marca Arcopay es una Entidad de Pago autorizada por el Banco de España para la prestación de los servicios de iniciación de pago y de información sobre cuentas.<br>
                  MORPHEUS AIOLOS S.L., con CIF B-86556420, está domiciliada en la Av. de Bruselas, 35, 28108, Alcobendas Madrid, y registrada en el Banco de España bajo el número de registro 6901. Además, se encuentra inscrita en el Registro Mercantil de Madrid, en concreto, en el Tomo 30408, Libro 0, Folio 12, Sección 8, Hoja M: 547305.<br>
                   Todos los servicios y productos prestados por MORPHEUS AIOLOS S.L. se han sometido a una exhaustiva revisión conforme a la normativa vigente y se encuentran supervisados por el Banco de España. En la página <a href="https://www.arcopay.io">www.arcopay.io</a> se dispone el contenido informativo sobre los servicios de pago ofrecidos por MORPHEUS AIOLOS S.L. a sus clientes, con el objetivo de facilitar la información relativa a los mismos. </p>
              </li>
            </ul>
          </div>
     
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useMeta } from 'vue-meta'
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  export default {
    name: 'StepOne',
    emits: ['next', 'back'],
    components: {
    },
    setup(props, { emit }) {
      const store = useStore();
  
      useMeta({
        title: 'Paso 1 - Ibancredit',
        meta: [
          { name: 'robots', content: store.state.dominio == 'ibancar.com' ? 'index' : 'noindex' },
          { name: 'description', content: 'Finaliza tu solicitud de préstamo pre-aprobado con Ibancredit' }
        ]
      })
    
      const nextStep = () => emit('next');
      const previousStep = () => emit('back');

      return {
        afiliado: computed(() => store.state.afiliado),
        nextStep,
        previousStep
      }
    }
  
  }
  </script>

  <style scoped lang="scss">
    @import "@/assets/styles/variables.scss";

    h3{
      text-align: left;
      font-size: 0.85rem;
      color: #AAA6A6;
      margin-bottom: 0;
      font-weight: 700;
    }

    .title{
      display: block;
      font-size: 1.57rem;
      line-height: normal;
      color: $blue;
      margin-bottom: 1rem;
      font-weight: bolder;
    }

    .subtitle {
      color: $blue;
      font-size: 0.813rem;
      margin-bottom: 2rem;
      font-weight: 500;
      line-height: normal;
    }

    .pre-approved-card {
      background: white;
      border-radius: 12px;
      padding: 2rem;
      //margin: 1rem;
      margin-top: 4rem;
      text-align: left;
    }

    .welcome-text {
      font-size: 1.5rem;
      font-weight: 600;
      color: $blue;
      margin-bottom: 0.5rem;
    }

    .btn-continue {
      background: $orange;
      color: white;
      border: none;
      padding: 0.65rem 2.5rem;
      font-size: 1.38rem;
      font-weight: 700;
      width: 100%;
      margin-bottom: 1.5rem;
      cursor: pointer;
      transition: background-color 0.2s;
      border-radius: 30px;
      box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);

    }

    .container-icons::before{
      width: 100%;
      height: 1px;
      background: #C4C4C4;
      content: '';
      position: absolute;
      top: 50%;
      z-index: 0;
      left: 0;
    }

    .container-icons{
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      margin: 1rem;
      position: relative;
    }

    .container-icons__item{
      width: auto;
      padding: 0 0.6rem;
      z-index: 1;
      background-color: white;
    }


    .steps{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    .steps__item{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.8rem;

       p{
        text-align: left;
        font-size: 0.85rem;
        color: #353739;
        margin-bottom: 0;
        line-height: normal;
        font-weight: 600;
       }
       img{
        width: 30px;
       }
    }

    .incomes{
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      ul{
        padding-left:0;
        gap: 0.6rem;
        display: flex;
        flex-direction: column;
        text-align: left;
      }

      li{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-right: 0.5rem;
        gap: 0.6rem;
      }

      img{
        width: 25px;
      }

      li.incomes__item__last{
        margin-top: 1rem;
        padding-left: 33px;

        a{
          color: $blue;
        }

        p{
          font-weight: 500;
          font-size: 0.75rem;
        }
      }

      p{
        font-size: 0.85rem;
        font-weight: 600;
        margin-bottom: 0;
        line-height: normal;
      }

    }


    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";


    @include media-breakpoint-up(md) {
      h3{
        font-size: 1rem;
      }

      .container-icons{
        margin: 2rem 5rem;
      }
    }

    @include media-breakpoint-only(lg) {
      .title{
        font-size: 2.6rem !important;
        text-align: center;
      }
    }

    @include media-breakpoint-up(lg) {
      .title{
        font-size: 3rem;
        margin-bottom: 1.4rem;
      }
      .subtitle{
        font-size: 1.5rem;
        font-weight: 600;
      }
      H3{
        font-size:2rem;
      }
      .btn-continue{
        max-width: 560px;
        font-size: 2rem;
        margin-top:3rem;
        justify-self: center;
        text-align: center;
        display: flex;
        justify-content: center;
      }
      .container-icons{
        margin: 4rem 8rem;

        .container-icons__item{
          padding: 0 2.8rem;
        }

        img{
          width: 85px;
        }
      }

      .steps{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2.8rem;
        margin: 3rem 0 4.5rem 0;
        .steps__item{
          position: relative;
          padding: 1rem 1.3rem;
          box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          p{
            text-align: center;
            color: $blue;
            font-weight: 700;
            margin-bottom: 0.5rem;
          }
        }

        ::before{
          font-size: 4.7rem;
          font-weight: 700;
          position: absolute;
          top: -40px;
          left: -18px;
          line-height: normal;
          color: rgb(255, 199, 95);
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        
        .steps__item--1::before{
          content: '1';
        }
        .steps__item--2::before{
          content: '2';
        }
        .steps__item--3::before{
          content: '3';
        }
        .steps__item--4::before{
          content: '4';
        }
        img{
          display: none;
        }
      }

      .btn-continue{
        margin-bottom: 5rem;;
      }


      .incomes{
        img{
          width: 40px;
        }

        ul{
          margin-top: 1.5rem;
          gap: 1.5rem;
        }
        li {
          gap: 1.2rem;
        }

        p{
          font-size: 1.75rem;
        }

        li.incomes__item__last{
          padding-left: 56px;

          p {
            font-size: 0.9rem;
          }
        }
      }
      
    }

    @include media-breakpoint-up(xl) {
      .steps{
        .steps__item{
          padding: 1rem 1.5rem;

          p{
            font-size: 1rem;
          }
        }
      }
    }
  </style>
