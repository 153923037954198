<template>
    <Container>
        <Modal v-if="showModal && !isAlerted && currentStep == 0" @next="nextStepModal" @close="closeModal" />
        <!-- This was the first page in the old design -->
        <div class="d-none">
          <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-11 col-xl-9">
              <!-- Pre-aprobado Card -->
              <div class="pre-approved-card">
                <h1 class="title">Tu préstamo sin aval, más fácil y rápido.</h1>
                <p class="subtitle"> <b>Hola Nombre.</b> <br><br>
                  Bienvenido a Ibancredit. Finaliza tu solicitud de préstamo en menos de 2 minutos.</p>
                
                <div class="loan-details">
                  <span class="loan-details-leyend">Pre-aprobado</span>
                  <div class="row">
                    <div class="col-12 col-loan-details">
                        <div class="detail-item">
                          <h2>6,000€</h2>
                          <p>Préstamo</p>
                        </div>
                        <div class="detail-item">
                          <h3>230€</h3>
                          <p>Cuotas</p>
                        </div>
                        <div class="detail-item">
                          <h3>36</h3>
                          <p>Meses</p>
                        </div>
                      </div>
                  </div>
                </div>
    
                <button class="btn-continue">
                  Continuar
                </button>
    
                <p class="footer-text">
                  Tu préstamo sin aval, más fácil y rápido.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- This was the first page in the old design -->
        <!-- Render the current component if the view is allowed to be shown -->
        <component 
          :is="shouldRenderView && currentComponent" 
          @next="nextStep" 
          @back="previousStep" 
          @refreshToken="refreshToken"
        />
        <!-- Render the current component if the view is allowed to be shown -->
    </Container>
  </template>
  
  <script setup>
  import { useMeta } from 'vue-meta'
  import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { StepOne, MiddleStep, StepTwo, ExpiredRequest, ThankYou, Container, Modal, FAQ } from '@/components/PreAprobado'   // Components
  import axios from 'axios'

  const store = useStore()
  const route = useRoute()
  const router = useRouter()

  useMeta({
    title: 'Pre-aprobado - Ibancredit',
    meta: [
      { name: 'robots', content: store.state.dominio == 'ibancar.com' ? 'index' : 'noindex' },
      { name: 'description', content: 'Finaliza tu solicitud de préstamo pre-aprobado con Ibancredit' }
    ]
  })
  // Grouped Paths
  const paths = [
    '/pre-aprobado',
    '/pre-aprobado/escoger-banco',
    '/pre-aprobado/paso-2',
    '/pre-aprobado/gracias'
  ];
  // Route Component Map
  const routeComponentMap = {
    '/pre-aprobado': StepOne,
    '/pre-aprobado/escoger-banco': MiddleStep,
    '/pre-aprobado/paso-2': StepTwo,
    '/pre-aprobado/solicitud-expirada': ExpiredRequest,
    '/pre-aprobado/gracias': ThankYou,
    '/pre-aprobado/preguntas-frecuentes': FAQ,
  }

  const sessionPreAprobado = computed(() => store.state.sessionPreAprobado)
  const currentComponent = computed(() => routeComponentMap[route.path] || StepOne)
  const currentStep = computed({
    get: () => paths.indexOf(route.path),
    set: (value) => redirectTo(paths[value])
  })
  const isAlerted = computed({
    get: () => store.state.preAprobado?.isAlerted || false,
    set: (value) => store.commit('setIsAlerted', value)
  })
  const mouseState = ref({
    isLeaving: false,
    lastX: 0,
    lastY: 0
  })
  const shouldRenderView = ref(false) // Only change true when the view is allowed to be shown
  const showModal = ref(false) // Show the modal when the user is leaving the page

  const closeModal = () => {
    showModal.value = false
    isAlerted.value = true
  }

  const refreshToken = async () => {
    await getUserSession(true)
  }; // Update the session with the new token and svg collection

  const redirectTo = (path) => {
    isAlerted.value = true;
    showModal.value = false;
    router.push(path)
  }

  const nextStep = () => {
    if (currentStep.value < paths.length - 1) {
      currentStep.value++
    }
  }

  const nextStepModal = () => {
    if (currentStep.value < paths.length - 1) {
      currentStep.value++
      showModal.value = false
    }
  }

  const previousStep = () => {
    if (currentStep.value > 0) {
      currentStep.value--
    }
  }

  // Get the user session, if refresh is true, we need to get the session again
  const getUserSession = async (refresh = false) => {
    const queryToken = route.query?.token ?? null;
    const sessionToken = sessionPreAprobado.value?.token ?? null;
    // If refresh prop is true, we need to get the session again
    if(!refresh){
      // If there is no query token and no session token, is not allowed to continue
      if(!queryToken && !sessionToken){ 
        return false;
      }
      // If there is a session token and no query token, is allowed to continue
      if(sessionToken && !queryToken){
        return true;
      }
      // If there is a session token and a query token and they match, is allowed to continue
      if(sessionToken && queryToken && queryToken === sessionToken){
        return true;
      } 
    }
    const token = queryToken ?? sessionToken;
    if(!token) return false;
    // Otherwise, get the session
    try {
      const response = await axios.get(`${process.env.VUE_APP_AXIOS_BASE_URL}ibancredit/token/${token}`)
      store.commit('setSessionPreAprobado', {token, ...response.data}) // Save the state
      return true;
    } catch (error) {
      return false;
    }
  }

  // Adding a watch for the token
  watch(() => route.query?.token, (newToken) => {
    if (newToken) {
      store.commit('setSessionPreAprobado', {token: newToken}) 
      getUserSession()
    }
  })



  onMounted(async () => {
    const isThankYouPage = route.path === '/pre-aprobado/gracias' || route.path === '/pre-aprobado/gracias/'
    const isStepOnePage = route.path === '/pre-aprobado';
    const allowAccess = await getUserSession();
    // Thank you page is allowed to be shown 'cause it's the last page
    if (!allowAccess && !isThankYouPage) router.push('/error');
    // Detect if the user is on the step two page and if the svg collection is not empty, redirect directly to the page
    if(allowAccess && sessionPreAprobado.value?.enlaces_svg?.length > 0 && isStepOnePage) router.push('/pre-aprobado/paso-2');
    // Only show the view if the user has access
    shouldRenderView.value = true
    // Mouse events
    document.documentElement.addEventListener('mousemove', handleMouseMove)
    document.documentElement.addEventListener('mouseout', handleMouseOut)
    document.documentElement.addEventListener('mouseleave', handleMouseLeave)
  })

  const handleMouseMove = (event) => {
    mouseState.value = {
      isLeaving: false,
      lastX: event.clientX,
      lastY: event.clientY
    }
  }

  const handleMouseOut = (event) => {
    // Checking if the mouse is actually leaving the page
    if (!event.relatedTarget && !event.toElement) {
      const verticalExit = event.clientY <= 0 || event.clientY >= window.innerHeight
      const horizontalExit = event.clientX <= 0 || event.clientX >= window.innerWidth
      
      if (verticalExit || horizontalExit) {
        mouseState.value.isLeaving = true
        showModal.value = true
      }
    }
  }

  const handleMouseLeave = (event) => {
    // Verification with old browsers
    if (mouseState.value.isLeaving && event.clientY <= 0) {
      showModal.value = true
    }
  }

  onUnmounted(() => {
    // Cleaning the event listeners
    document.documentElement.removeEventListener('mousemove', handleMouseMove)
    document.documentElement.removeEventListener('mouseout', handleMouseOut)
    document.documentElement.removeEventListener('mouseleave', handleMouseLeave)
  })


  </script>

  <style scoped lang="scss">
    @import "@/assets/styles/variables.scss";

    .title{
      display: none;
    }

    .pre-approved-card {
      background: white;
      border-radius: 12px;
      padding: 2rem;
      //margin: 1rem;
      margin-top: 4rem;
      text-align: left;
    }

    .welcome-text {
      font-size: 1.5rem;
      font-weight: 600;
      color: $blue;
      margin-bottom: 0.5rem;
    }

    .subtitle {
      color: $blue;
      font-size: 0.813rem;
      margin-bottom: 2rem;
      font-weight: 500;
    }

    .loan-details {
      background: #fff;
      border: 4px solid #B3DCFF;
      border-radius: 10px 10px 20px 20px;
      margin-bottom: 2rem;
      margin-top: 4rem;
      position: relative;
      padding: 4.5rem 1rem 1rem 1rem;
      color: $blue;
      min-width: 170px;
      width: 53%;
      justify-self: center;

      span{
        position: absolute;
        top: 0;
        left: 0px;
        width: 100%;
        text-align: center;
        background: #B3DCFF;
        padding: 0.53rem 1rem;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        color:rgba(53, 55, 57, 0.79);
        font-weight: 600;
        background: #B3DCFF;
      }

      .col-loan-details{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
      }
    }

    .loan-amount {
      font-size: 2.5rem;
      font-weight: bold;
      color: $blue;
      margin-bottom: 0.5rem;
    }

    .details-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      margin-top: 1.5rem;
    }

    .detail-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.6rem;
      
      h2{
        font-weight: 700;
        font-size: 1.8rem;
        margin-bottom: 0;
        min-height: 20px;
      }

      h3 {
        font-size: 1.3rem;
        font-weight: 700;
        color: $blue;
        margin-bottom: 0;
        min-height: 20px;

      }
      
      p {
        font-size: 0.875rem;
        color: $blue;
        margin: 0;
      }
    }

    .btn-continue {
      background: $orange;
      color: white;
      border: none;
      padding: 0.65rem 2.5rem;
      font-size: 1.38rem;
      font-weight: 700;
      width: 100%;
      margin-bottom: 1.5rem;
      cursor: pointer;
      transition: background-color 0.2s;
      border-radius: 30px;
      box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);

    }

    .footer-text {
      color: $blue;
      font-size: 1.2rem;
      padding: 0  2rem;
      text-align: center;
      font-weight: 700;
      margin: 0;
      margin-bottom: 1.5rem;
    }


    @import "bootstrap/scss/_functions.scss";
    @import "bootstrap/scss/_variables.scss";
    @import "bootstrap/scss/_mixins.scss";

    @include media-breakpoint-only(lg) {
      .title{
        font-size: 2.6rem !important;
      }
    }

    @include media-breakpoint-up(lg) {
      .title{
        display: block;
        font-size: 3rem;
        color: $blue;
        margin-bottom: 2rem;
        font-weight: bolder;
      }
      .subtitle{
        font-size: 1.7rem;
      }
      .loan-details{
        max-width: 762px;
        justify-self: center;
        width: 100%;
        margin-top: 7rem;
        padding: 2.5rem 1rem 2.5rem 1rem;

        .loan-details-leyend{
          max-width: 336px;
          left:-4px;
          top: -3.8rem;
          font-size: 1.75rem;
          border-radius: 8px;
          padding: 0.61rem 1.2rem;
        }

        h2{
          font-size: 3.5rem;
        }
        h3{
          font-size: 2.65rem;
        }
        p{
          font-size: 1.36rem;
          font-weight: 500;
        }

        .col-loan-details{
          flex-direction: row;
          justify-content: space-around;
          align-items: flex-end;
          gap: 2rem;
        }
        
      }

      .btn-continue{
        max-width: 560px;
        font-size: 2rem;
        margin-top:3rem;
        justify-self: center;
        text-align: center;
        display: flex;
        justify-content: center;
      }

      .pre-approved-card{
        padding-bottom: 8rem;
      }

      .footer-text{
        display: none;
      }
    }
  </style>
